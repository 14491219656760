import React, { memo, useCallback } from 'react';
import { Button, Typography } from '@mui/material';

import { ReactComponent as ArrowForward } from 'assets/icons/arrow.svg';
import { styles } from 'common/components/PayButton/PayButton.styles';

const PayButtonBase = ({
  title,
  currency,
  amount,
  redirect,
  handlePay,
  customStyles = {},
}) => {
  const handleClick = useCallback(
    () =>
      handlePay({
        redirect,
        currency,
        amount,
      }),
    [amount, currency, handlePay, redirect],
  );

  const titleText = title ? title : `${amount} ${currency}`;

  return (
    <Button
      sx={Object.assign({}, styles.button, customStyles)}
      onClick={handleClick}
    >
      <Typography sx={styles.buttonText} variant="h3">
        {titleText}
      </Typography>
      <ArrowForward />
    </Button>
  );
};

const PayButton = memo(PayButtonBase);

export default PayButton;
