import React, { memo } from 'react';
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
} from '@mui/material';
import { motion } from 'framer-motion';

import { PayButton } from 'common/components';
import { usePay } from 'common/hooks';

import {
  SOLamount,
  DHMTamount,
  SHDWamount,
  SOL,
  SHDW,
  DMND,
  FREE,
} from 'common/static/constants';
import { styles } from './ChoiceCard.styles';
import staticContent from 'common/static/content.json';

const { start, comming } = staticContent.pages.main;

const ChoiceCardBase = ({
  card,
  customStyles = {},
  inlineStyles = {},
  ...props
}) => {
  const { handlePay } = usePay();

  const {
    title,
    image,
    latest = false,
    amount,
    payment,
    redirect,
    available,
    description,
    transitionDelay,
  } = card;

  return (
    <Card
      sx={{ ...styles.card, ...customStyles, bgcolor: latest ? '#013220' : '' }}
      style={{ ...inlineStyles }}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: transitionDelay }}
      {...props}
    >
      <Box sx={styles.mediaBox}>
        {latest && <Box sx={styles.label}>New</Box>}

        <CardMedia
          sx={styles.image}
          alt={title}
          image={image}
          component="img"
          height="240"
        />
      </Box>

      <CardContent sx={styles.content}>
        <Typography sx={styles.title} variant="h3">
          {title}
        </Typography>
        <Typography sx={styles.description} variant="body1">
          {description}
        </Typography>
      </CardContent>

      <CardActions sx={styles.actions} disableSpacing>
        <Typography sx={styles.start} variant="h3">
          {start}
        </Typography>

        <Box sx={styles.buttons}>
          {available ? (
            <>
              {payment.sol && (
                <PayButton
                  currency={SOL}
                  amount={amount?.sol || SOLamount}
                  redirect={redirect}
                  handlePay={handlePay}
                />
              )}

              {payment.dhmt && (
                <PayButton
                  currency={DMND}
                  amount={amount?.dhmt || DHMTamount}
                  redirect={redirect}
                  handlePay={handlePay}
                />
              )}

              {payment.shdw && (
                <PayButton
                  currency={SHDW}
                  amount={SHDWamount}
                  redirect={redirect}
                  handlePay={handlePay}
                />
              )}

              {payment.free && (
                <PayButton
                  title="Open"
                  currency={FREE}
                  redirect={redirect}
                  handlePay={handlePay}
                />
              )}
            </>
          ) : (
            <Typography sx={styles.soon} variant="h3">
              {comming}
            </Typography>
          )}
        </Box>
      </CardActions>
    </Card>
  );
};

const ChoiceCard = memo(ChoiceCardBase);

export default ChoiceCard;
