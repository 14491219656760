import cross from 'common/static/crossword.json';

export const initialResults = {
  across: [
    {
      number: 1,
      clue: cross.across[1].clue,
      answer: '',
      grid: ['1_0', '1_1', '1_2', '1_3', '1_4'],
    },
    {
      number: 2,
      clue: cross.across[2].clue,
      answer: '',
      grid: ['0_15', '0_16', '0_17', '0_18', '0_19', '0_20', '0_21', '0_22'],
    },
    {
      number: 3,
      clue: cross.across[3].clue,
      answer: '',
      grid: ['20_13', '20_14', '20_15', '20_16', '20_17'],
    },
    {
      number: 4,
      clue: cross.across[4].clue,
      answer: '',
      grid: ['4_11', '4_12', '4_13', '4_14', '4_15'],
    },
    {
      number: 5,
      clue: cross.across[5].clue,
      answer: '',
      grid: ['8_7', '8_8', '8_9', '8_10', '8_11', '8_12', '8_13'],
    },
    {
      number: 6,
      clue: cross.across[6].clue,
      answer: '',
      grid: ['7_0', '7_1', '7_2', '7_3', '7_4', '7_5'],
    },
    {
      number: 7,
      clue: cross.across[7].clue,
      answer: '',
      grid: ['9_0', '9_1', '9_2', '9_3', '9_4', '9_5', '9_6', '9_7'],
    },
    {
      number: 8,
      clue: cross.across[8].clue,
      answer: '',
      grid: [
        '10_11',
        '10_12',
        '10_13',
        '10_14',
        '10_15',
        '10_16',
        '10_17',
        '10_18',
        '10_19',
        '10_20',
        '10_21',
      ],
    },
    {
      number: 9,
      clue: cross.across[9].clue,
      answer: '',
      grid: ['14_20', '14_21', '14_22', '14_23'],
    },
    {
      number: 10,
      clue: cross.across[10].clue,
      answer: '',
      grid: ['15_0', '15_1', '15_2', '15_3', '15_4'],
    },
    {
      number: 11,
      clue: cross.across[11].clue,
      answer: '',
      grid: [
        '15_7',
        '15_8',
        '15_9',
        '15_10',
        '15_11',
        '15_12',
        '15_13',
        '15_14',
        '15_15',
        '15_16',
      ],
    },
    {
      number: 12,
      clue: cross.across[12].clue,
      answer: '',
      grid: ['17_5', '17_6', '17_7', '17_8', '17_9', '17_10'],
    },
    {
      number: 13,
      clue: cross.across[13].clue,
      answer: '',
      grid: ['17_12', '17_13', '17_14', '17_15', '17_16', '17_17', '17_18'],
    },
    {
      number: 14,
      clue: cross.across[14].clue,
      answer: '',
      grid: ['19_9', '19_10', '19_11', '19_12', '19_13', '19_14', '19_15'],
    },
    {
      number: 15,
      clue: cross.across[15].clue,
      answer: '',
      grid: ['4_0', '4_1', '4_2', '4_3', '4_4'],
    },
  ],
  down: [
    {
      number: 1,
      clue: cross.down[1].clue,
      answer: '',
      grid: ['16_6', '17_6', '18_6', '19_6', '20_6', '21_6'],
    },
    {
      number: 2,
      clue: cross.down[2].clue,
      answer: '',
      grid: ['1_3', '2_3', '3_3', '4_3', '5_3', '6_3', '7_3'],
    },
    {
      number: 3,
      clue: cross.down[3].clue,
      answer: '',
      grid: [
        '0_19',
        '1_19',
        '2_19',
        '3_19',
        '4_19',
        '5_19',
        '6_19',
        '7_19',
        '8_19',
        '9_19',
        '10_19',
      ],
    },
    {
      number: 4,
      clue: cross.down[4].clue,
      answer: '',
      grid: [
        '2_17',
        '3_17',
        '4_17',
        '5_17',
        '6_17',
        '7_17',
        '8_17',
        '9_17',
        '10_17',
        '11_17',
        '12_17',
        '13_17',
        '14_17',
      ],
    },
    {
      number: 5,
      clue: cross.down[5].clue,
      answer: '',
      grid: ['3_15', '4_15', '5_15', '6_15', '7_15'],
    },
    {
      number: 6,
      clue: cross.down[6].clue,
      answer: '',
      grid: ['4_1', '5_1', '6_1', '7_1', '8_1', '9_1', '10_1', '11_1'],
    },
    {
      number: 7,
      clue: cross.down[7].clue,
      answer: '',
      grid: ['4_7', '5_7', '6_7', '7_7', '8_7', '9_7', '10_7', '11_7', '12_7'],
    },
    {
      number: 8,
      clue: cross.down[8].clue,
      answer: '',
      grid: [
        '4_9',
        '5_9',
        '6_9',
        '7_9',
        '8_9',
        '9_9',
        '10_9',
        '11_9',
        '12_9',
        '13_9',
      ],
    },
    {
      number: 9,
      clue: cross.down[9].clue,
      answer: '',
      grid: [
        '4_11',
        '5_11',
        '6_11',
        '7_11',
        '8_11',
        '9_11',
        '10_11',
        '11_11',
        '12_11',
        '13_11',
      ],
    },
    {
      number: 10,
      clue: cross.down[10].clue,
      answer: '',
      grid: [
        '5_21',
        '6_21',
        '7_21',
        '8_21',
        '9_21',
        '10_21',
        '11_21',
        '12_21',
        '13_21',
        '14_21',
        '15_21',
      ],
    },
    {
      number: 11,
      clue: cross.down[11].clue,
      answer: '',
      grid: [
        '6_13',
        '7_13',
        '8_13',
        '9_13',
        '10_13',
        '11_13',
        '12_13',
        '13_13',
        '14_13',
        '15_13',
      ],
    },
    {
      number: 12,
      clue: cross.down[12].clue,
      answer: '',
      grid: ['9_4', '10_4', '11_4', '12_4', '13_4', '14_4', '15_4'],
    },
    {
      number: 13,
      clue: cross.down[13].clue,
      answer: '',
      grid: [
        '13_15',
        '14_15',
        '15_15',
        '16_15',
        '17_15',
        '18_15',
        '19_15',
        '20_15',
      ],
    },
    {
      number: 14,
      clue: cross.down[14].clue,
      answer: '',
      grid: ['14_7', '15_7', '16_7', '17_7', '18_7', '19_7'],
    },
  ],
};

/**
Crossword Clues

@VITALIK - The first name of the Ethereum creator
@MACRO - Used to indicate a long term view / time horizon
@CRYPTONARY - Your best guide in the crypto market, and source for accurate and impartial research
@SHADOW - The token attached to the SSC NFTs, which also allows user access to decentralised storage
@RUGPULL - What Lil Uzi Verts NFT project on Solana could be characterised as, and something every NFT degen has ended up owning.
@DERIVATIVES - A sector we expect to reach a market cap of a 100 quadrillion
@YELLOW - Colour of Bills Glasses
@ZEROKNOWLEDGE - The scaling mechanism that executes transactions off chain using merkle tress. Two words no spaces
@CENTRALISED - A system or process for which there is a singular source of authority, control and/or truth
@STABLECOIN - Tokens whose value aims to be constant
@SATOSHI - The first name of the Bitcoin creator
@PONZI - The model that many coins use to generate revenue, rewarding older holders with capital from new entrants
@SHARDING - A key element which will expand the throughput of Ethereum
@STAKE - What you should be doing with some of your Solana, and the mechanism that secures the network aka Proof of ...
@SOLANA - The base layer this game is built on
@ETHEREUM - Karims favourite cryptocurrency
@MERGE - A major technological advancement and key narrative for Ethereum this year... it should reduce those gas fees too
@OPTIMISTIC - A positive outlook on life, and the other ETH scaling solution type
@LEVERAGE - The instrument that allows traders to increase the size of their positions aka what Uncle Sams Casino offers
@ROLLUPS - An ETH scaling mechanism which has two types
@DMNDS - A precious gem and also a token all DH holders are accruing via in wallet staking
@TOKENOMICS - The study of what a token is used for and how it is distributed
@YAKOVENKO - The surname of one of the Solana Creators
@LIQUIDATION - What happens to your account when you are long BTC and it dumps HARD
@SHA256 - The encryption algorithm used by Solana for it's PoH validation mechanism
@BITCOIN - The first cryptocurrency to be created
@SHITCOIN - The kinda coin you don't want to buy - usually shilled by influencers
@PERPS - Futures that have no expiration date - they have also seen the most volume in crypto
@FORK - An eating utensil. Also commonly used in relationship to ETH upgrades
 */
