import { Navigate } from 'react-router-dom';
import { useProvider } from 'common/hooks';
import { routes } from 'routes';

const PrivateRoute = ({ component: Component }) => {
  const { transferTokenStatus } = useProvider();

  return transferTokenStatus ? Component : <Navigate to={routes.home} />;
};

export default PrivateRoute;
