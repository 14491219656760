/* This function uses a key to encrypt a given string.
 * First, it replaces any special characters with their equivalent characters - for example: @ is replaced with 'aaa'.
 * It then iterates through the encryption key and creates a list of shift values.
 * The encrypted message is initialized as an empty string.
 * Next, the function iterates through the characters in the message, checking if the character is a space, uppercase or lowercase.
 * Depending on the character type, it shifts the character by the appropriate amount and adds it to the cipher.
 * Finally, the function returns the completed cipher.
 */

export const encryptString = async (message = '', encryption_key) => {
  if (!message || !encryption_key) return;

  let modifiedMessage = message;

  console.log('message :>> ', message);
  console.log('encryption_key :>> ', encryption_key);

  //remove the @ and replace with |
  modifiedMessage = modifiedMessage.replace('@', 'aaa');
  modifiedMessage = modifiedMessage.replace('.', 'dot');
  modifiedMessage = modifiedMessage.replace('1', 'one');
  modifiedMessage = modifiedMessage.replace('2', 'two');
  modifiedMessage = modifiedMessage.replace('3', 'three');
  modifiedMessage = modifiedMessage.replace('4', 'four');
  modifiedMessage = modifiedMessage.replace('5', 'five');
  modifiedMessage = modifiedMessage.replace('6', 'six');
  modifiedMessage = modifiedMessage.replace('7', 'seven');
  modifiedMessage = modifiedMessage.replace('8', 'eight');
  modifiedMessage = modifiedMessage.replace('9', 'nine');

  console.log('modifiedMessage :>> ', modifiedMessage);

  // Iterate through the encryption key and create a list of shift values
  let shift_values = [1];

  for (let char of encryption_key) {
    if (char.match(/[0-9]/)) {
      shift_values.push(parseInt(char));
    }
  }

  console.log('shift_values :>> ', shift_values);

  // The encrypted message is initialized as an empty string
  let cipher = '';

  // Iterate through the characters in the message
  let shift_index = 0;

  for (let char of modifiedMessage) {
    // If the character is a space, add it to the cipher as is
    if (char === ' ') {
      cipher = cipher + char;

      // If the character is uppercase, cipher the character using the shift and the character's ord value
    } else if (char.match(/[A-Z]/)) {
      let shift_value = shift_values[shift_index % shift_values.length];
      cipher =
        cipher +
        String.fromCharCode(
          ((char.charCodeAt(0) + shift_value - 65) % 26) + 65,
        );

      // If the character is lowercase, cipher the character using the shift and the character's ord value
    } else {
      let shift_value = shift_values[shift_index % shift_values.length];
      cipher =
        cipher +
        String.fromCharCode(
          ((char.charCodeAt(0) + shift_value - 97) % 26) + 97,
        );
    }

    // Increment the shift index
    shift_index += 1;
  }

  console.log('cipher :>> ', cipher);

  return cipher;
};
