import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import { AppBar, AppWrapper } from 'common/layout';
import { Loader } from 'common/components';
import { styles } from 'common/layout/ArticlesLayout/ArticlesLayout.styles';

const ArticlesLayout = () => (
  <Box sx={styles.layout}>
    <AppBar />
    <AppWrapper>
      <Suspense fallback={<Loader isLoading />}>
        <Outlet />
      </Suspense>
    </AppWrapper>
  </Box>
);

export default ArticlesLayout;
