import React from 'react';
import { useModal } from 'common/hooks';
import {
  ModalSubmit,
  ModalSuccess,
  ModalConfirmation,
} from 'common/components';

const Modal = () => {
  const { modalType } = useModal();

  return (
    <>
      {modalType === 'submit' && <ModalSubmit />}
      {modalType === 'success' && <ModalSuccess />}
      {modalType === 'confirm' && <ModalConfirmation />}
    </>
  );
};

export default Modal;
