import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Menu as MenuIcon } from '@mui/icons-material';
import {
  Box,
  AppBar as AppHeading,
  Toolbar,
  Typography,
  IconButton,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { Logo, Timer } from 'common/components';
import { useProvider } from 'common/hooks';
import { setProvider } from 'redux/provider/providerSlice';
import { getTimerDisplayed } from 'common/utils/misc';
import { styles } from 'common/layout/AppBar/AppBar.styles';
import { routes } from 'routes';

import staticContent from 'common/static/content.json';

const { connected, notConnected, walletButton } = staticContent.header;

const AppBar = ({ toggleDrawer }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const { provider, providerPubKey } = useProvider();

  const loginHandler = () => {
    if (!provider && window.solana) {
      dispatch(setProvider(window.solana));
    } else if (!provider) {
      console.log('No provider found');
      return;
    } else if (provider && !provider.isConnected) {
      provider.connect();
    }
  };

  const handleBurgerClick = useCallback(() => {
    if (toggleDrawer) toggleDrawer(true);
  }, [toggleDrawer]);

  const displayTimer = getTimerDisplayed(location?.pathname, routes);

  const isWalletButtonActive =
    location?.pathname === routes.home && !providerPubKey;

  const isWalletStatusActive =
    location?.pathname !== `/${routes.crossword}` && providerPubKey;

  const isBurgerButtonActive = location?.pathname === `/${routes.crossword}`;

  return (
    <AppHeading sx={styles.header} position="static">
      <Toolbar sx={styles.toolbar}>
        <Logo />
        {displayTimer && <Timer />}

        <Box sx={styles.wallet}>
          {isWalletButtonActive && (
            <Button
              color="secondary"
              variant="contained"
              sx={styles.button}
              onClick={loginHandler}
            >
              {walletButton}
            </Button>
          )}

          {isWalletStatusActive && (
            <>
              <Typography sx={styles.key}>
                {providerPubKey?.toBase58()}
              </Typography>
              <Typography
                sx={providerPubKey ? styles.connected : styles.notConnected}
              >
                {providerPubKey ? connected : notConnected}
              </Typography>
            </>
          )}

          {isBurgerButtonActive && matches && (
            <>
              <Typography sx={styles.key}>
                {providerPubKey?.toBase58()}
              </Typography>
              <Typography
                sx={providerPubKey ? styles.connected : styles.notConnected}
              >
                {providerPubKey ? connected : notConnected}
              </Typography>
            </>
          )}

          {isBurgerButtonActive && !matches && (
            <IconButton sx={styles.burgerBtn} onClick={handleBurgerClick}>
              <MenuIcon />
            </IconButton>
          )}
        </Box>
      </Toolbar>
    </AppHeading>
  );
};

export default AppBar;
