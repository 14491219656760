export const styles = {
  faq: {
    maxWidth: 540,
    width: '100%',
  },
  title: {
    fontSize: '24px',
    mb: '32px',
  },
};
