export const styles = {
  footer: {
    gridArea: 'AppBasement',
    borderTop: theme => `1px solid ${theme.palette.success.main}`,
    padding: '36px 0',
  },
  footerSticky: {
    position: 'sticky',
    bottom: 0,
    gridArea: 'AppBasement',
    borderTop: 'none',
    padding: '36px 0',
    bgcolor: 'custom.black',
  },
};

export const toolbarStyles = {
  link: {
    color: 'custom.white',
    ml: '8px',
    textDecoration: 'underline',
  },
  footer: {
    gridArea: 'AppBasement',
    borderTop: theme => `1px solid ${theme.palette.success.main}`,
    padding: '36px 0',
  },
  footerSticky: {
    position: 'sticky',
    bottom: 0,
    gridArea: 'AppBasement',
    borderTop: 'none',
    padding: '36px 0',
    bgcolor: 'custom.black',
  },
  toolbar: {
    display: 'flex',
    flexDirection: { xs: 'column-reverse', md: 'row' },
    justifyContent: { md: 'space-between' },
  },
  technical: {
    display: 'flex',
    padding: { xs: '32px 0', md: '0' },
  },
  buttons: {
    display: 'flex',
    flexDirection: { xs: 'column-reverse', md: 'row' },
    width: { xs: '100%', md: 'fit-content' },
    gap: '16px',
    paddingBottom: { xs: '32px', md: '0' },
    borderBottom: { xs: '1px solid #404040', md: 'none' },
  },
  reset: {
    padding: '16px 24px',
  },
  key: {
    maxWidth: '120px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  connected: {
    color: theme => theme.palette.custom.apple,
    ml: '16px',
  },
  notConnected: {
    color: '#FF0000',
    ml: '16px',
  },
  provider: {
    display: 'flex',
    width: '100%',
    paddingTop: '32px',
    justifyContent: 'center',
    borderTop: theme => `1px solid ${theme.palette.success.main}`,
  },
};
