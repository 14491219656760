import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal as SubmitModal,
  Drawer,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { ModalContent } from 'common/components';
import { useModal, usePay } from 'common/hooks';
import { resultsToString } from 'common/utils/misc';
import { DMND } from 'common/static/constants';
import { modalClosed } from 'redux/modal/modalSlice';
import { initialResults } from 'common/static/results';

const ModalSubmit = () => {
  const [totalGuesses, setTotalGuesses] = useState(0);

  const { isModalOpen } = useModal();
  const { handlePay } = usePay();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const getGuessesTotal = useCallback(axis => {
    initialResults[axis].map(guess => {
      if (guess.answer.length > 0) setTotalGuesses(prevState => prevState + 1);
    });
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      setTotalGuesses(0);
      getGuessesTotal('across');
      getGuessesTotal('down');
    }
  }, [getGuessesTotal, isModalOpen]);

  const handleClose = useCallback(() => {
    dispatch(modalClosed());
  }, [dispatch]);

  const handlePayToSubmit = useCallback(async () => {
    const totalResultsString = resultsToString();

    handleClose();
    handlePay({
      currency: DMND,
      hashMemo: totalResultsString,
      isResultSubmit: true,
    });
  }, [handleClose, handlePay]);

  return (
    <>
      {matches && (
        <SubmitModal open={isModalOpen} onClose={handleClose}>
          <ModalContent
            totalGuesses={totalGuesses}
            handleSubmit={handlePayToSubmit}
            initialResults={initialResults}
          />
        </SubmitModal>
      )}

      {!matches && (
        <Drawer
          PaperProps={{
            sx: {
              top: '60px',
            },
          }}
          anchor="bottom"
          open={isModalOpen}
          onClose={handleClose}
        >
          <ModalContent
            totalGuesses={totalGuesses}
            handleSubmit={handlePayToSubmit}
            initialResults={initialResults}
          />
        </Drawer>
      )}
    </>
  );
};

export default ModalSubmit;
