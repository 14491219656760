import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import * as splToken from '@solana/spl-token';

import { useProvider } from 'common/hooks';
import { setTransactionSignature } from 'redux/provider/providerSlice';
import { loaderActive, loaderDisabled } from 'redux/loader/loaderSlice';
import { notificationOpened } from 'redux/notification/notificationSlice';
import { modalOpened } from 'redux/modal/modalSlice';
import { setTransferTokenStatus } from 'redux/provider/providerSlice';

import { transferCustomToken } from 'common/utils/transferToken';
import { transferDiamondToken } from 'common/utils/transferDiamond';
// import { createNFTandDiamondTransfer } from 'common/utils/transferNFTandDiamondToken';
import { encryptString } from 'common/utils/encryptString';
import { sendEmail } from 'common/utils/misc';
import { routes } from 'routes';
import 'common/utils/bufferFill';

const sha1 = require('sha1');

import {
  diamondsRequiredToPlay,
  gameWalletPublicKey,
  memberPubkey,
  crosswordWalletPublicKey,
  shadowMint,
  shadowRequiredToPlay,
  lamportsRequiredToPlay,
  tokenMint,
  utilMemo,
  // memberAddress,
  // nonMemberAddress,
  diamondsToClaim,
  DMND,
  FREE,
  SHDW,
  SOL,
  mintUrl,
  solscanUrl,
} from 'common/static/constants';
import { postTransactionData, emailCollectionData } from 'queries/instance';

const usePay = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { connection, provider, providerPubKey } = useProvider();

  const paySOL = useCallback(
    async ({
      redirect,
      currency,
      amount,
      hashMemo,
      nftMint,
      emailAddress,
      member,
      isResultSubmit,
      endpoint,
    }) => {
      /*
       * Flow to play the game
       * 1. Check the wallet has SOL in it
       * 2. If no SOL then ask him to fund the wallet first
       * 3. If required SOL present the, proceed with the transaction
       *
       */

      /*
       * Check if the user has SOL in his wallet
       */

      const accountBalance = await connection.getBalance(providerPubKey);
      const balanceInLamports = accountBalance ? parseInt(accountBalance) : 0;

      if (balanceInLamports < lamportsRequiredToPlay) {
        const fundNeededToPlay = lamportsRequiredToPlay - balanceInLamports;

        enqueueSnackbar(
          `Please fund your wallet with ${
            fundNeededToPlay / LAMPORTS_PER_SOL
          } SOL tokens`,
          {
            variant: 'info',
          },
        );

        return null;
      }

      /*
       * If user has required SOL in the wallet, then deduct the amount
       */

      dispatch(loaderActive());

      const requiredLamports = lamportsRequiredToPlay / LAMPORTS_PER_SOL;

      const result = await transferCustomToken(
        provider,
        connection,
        requiredLamports,
        providerPubKey,
        gameWalletPublicKey,
      );

      if (!result.status) {
        dispatch(setTransferTokenStatus(result.status));
        dispatch(loaderDisabled());

        enqueueSnackbar(
          result.error
            ? result.error
            : 'Error in sending the tokens, Please try again',
          {
            variant: 'error',
          },
        );

        return;
      }

      /*
       * If the status is true, that means transaction got successful and we can proceed
       */

      dispatch(setTransferTokenStatus(result.status));
      dispatch(loaderDisabled());

      if (redirect) navigate(redirect);
    },
    [connection, dispatch, enqueueSnackbar, navigate, provider, providerPubKey],
  );

  const payDHMT = useCallback(
    async ({
      redirect,
      currency,
      amount,
      hashMemo,
      nftMint,
      emailAddress,
      member,
      isResultSubmit,
      endpoint,
    }) => {
      /*
       * Flow to play the game
       * 1. Check the wallet has a DMND in it
       * 2. If no DMND then ask them to stake and get some
       * 3. If Diamond present then proceed
       * Check if the user is logged in
       */

      /*
       * Check if the user has diamonds in their wallet
       * And use the value to check if they can afford the game
       */
      const diamondAddress = await splToken.getAssociatedTokenAddress(
        currency === SHDW ? shadowMint : tokenMint,
        providerPubKey,
      );

      /*
       * Output the ATA to console to check manually
       * TODO!!!! ADD ERROR HANDLE IF ATA NOT FOUND
       */
      // console.log(diamondAddress.toString());
      // console.log('found ATA');

      /*
       * Address found and we pull balance succesfully here
       * Print to console the amount to check
       */

      try {
        const diamondBalance = await connection.getTokenAccountBalance(
          diamondAddress,
        );

        /*
         * Go here and check to see they can afford with diamonds
         */

        if (diamondBalance?.value?.amount < 1) {
          enqueueSnackbar('You need to have at least 1 DMND', {
            variant: 'info',
          });

          return;
        }

        /*
         * Time to get them to send us their Diamond
         * For this we need to use the Associated token accounts
         * We know the accs will exist as the payer has diamonds to have gotten to this stage
         * we call our custom function here to do this
         */

        let walletPubKey = gameWalletPublicKey;
        let requiredToPlay = diamondsRequiredToPlay;

        if (emailAddress) {
          walletPubKey = memberPubkey;
          requiredToPlay = diamondsToClaim;
          console.log('memberPubkey :>>', walletPubKey.toString());
          console.log('diamonds owned :>>', diamondBalance.value.amount);
          console.log('nft :>>', nftMint);
        }

        if (isResultSubmit) {
          walletPubKey = crosswordWalletPublicKey;
          console.log('crosswordWalletPublicKey :>> ', walletPubKey);
        }

        if (currency === SHDW) {
          requiredToPlay = shadowRequiredToPlay;
        }

        dispatch(loaderActive());

        /*Use the one below if they click the 4mo button*/

        let result = null;
        const encryptEmail = await encryptString(
          emailAddress,
          providerPubKey.toBase58(),
        );

        console.log('encryptEmail :>>>>>>>> ', encryptEmail);

        if (member === '4mo') {
          result = await transferDiamondToken(
            provider,
            connection,
            tokenMint,
            providerPubKey,
            walletPubKey,
            diamondBalance.value.amount,
            requiredToPlay,
            nftMint + '|' + encryptEmail,
          );
        } else if (member === '3mo') {
          result = await transferDiamondToken(
            provider,
            connection,
            tokenMint,
            providerPubKey,
            walletPubKey,
            diamondBalance.value.amount,
            requiredToPlay,
            nftMint + '|' + encryptEmail,
          );
        } else {
          result = await transferDiamondToken(
            provider,
            connection,
            currency === SHDW ? shadowMint : tokenMint,
            providerPubKey,
            walletPubKey,
            diamondBalance.value.amount,
            amount ? amount : requiredToPlay,
            endpoint === 'collection'
              ? sha1(hashMemo)
              : hashMemo
              ? hashMemo
              : utilMemo,
          );
        }

        /*
        const result = await transferDiamondToken(
          provider,
          connection,
          currency === SHDW ? shadowMint : tokenMint,
          providerPubKey,
          walletPubKey,
          diamondBalance.value.amount,
          requiredToPlay,
          hashMemo ? hashMemo : utilMemo,
        );
				*/

        /*Use the one below if they click the 3mo button*/
        // const result = await createNFTandDiamondTransfer(
        //   provider,
        //   connection,
        //   currency === SHDW ? shadowMint : tokenMint,
        //   providerPubKey,
        //   walletPubKey,
        //   diamondBalance.value.amount,
        //   requiredToPlay,
        //   hashMemo ? hashMemo : utilMemo,
        //   nftMint,
        // );

        console.log('result.status', result.status);

        if (!result.status) {
          dispatch(loaderDisabled());

          enqueueSnackbar('Error in sending the tokens, please try again', {
            variant: 'error',
          });

          return;
        }

        if (result.signature) {
          dispatch(setTransferTokenStatus(result.status));
          dispatch(setTransactionSignature(result.signature));
        }

        // if (endpoint === 'collection') {
        //   await emailCollectionData(
        //     providerPubKey?.toBase58(),
        //     sha1(hashMemo),
        //     {
        //       info: hashMemo,
        //     },
        //   );
        // }

        if (endpoint === 'member') {
          await postTransactionData(nftMint, {
            nftmint: nftMint,
            email: emailAddress,
            ...(member === '4mo' ? { claim: member } : {}),
            ...(member === '3mo' ? { claim: member } : {}),
            tx: result?.signature,
          });
        }

        if (emailAddress && endpoint !== 'collection') {
          sendEmail({
            sendToEmail: emailAddress,
            userAddress: null,
            role: 'user',
            signature: result.signature,
          });

          // member
          //   ? sendEmail(memberAddress, emailAddress, 'member', result.signature)
          //   : sendEmail(
          //       nonMemberAddress,
          //       emailAddress,
          //       'member',
          //       result.signature,
          //     );
        }

        /*
         * If the status is true, that means transaction got successful and we can proceed
         */

        dispatch(
          notificationOpened({
            open: true,
            message: 'Submitted transaction confirmed',
            severity: 'success',
            tx: emailAddress ? `${solscanUrl}/${result.signature}` : '',
          }),
        );

        dispatch(loaderDisabled());

        if (isResultSubmit) dispatch(modalOpened('success'));
        if (redirect) navigate(redirect);
      } catch (error) {
        console.warn(error);
        // enqueueSnackbar('Failed to get token account balance', {
        //   variant: 'error',
        // });
      } finally {
        dispatch(loaderDisabled());
      }
    },
    [connection, dispatch, enqueueSnackbar, navigate, provider, providerPubKey],
  );

  const handlePay = useCallback(
    ({
      redirect = null,
      currency = null,
      amount = null,
      hashMemo = null,
      nftMint = null,
      emailAddress = null,
      member = null,
      isResultSubmit = false,
      endpoint = null,
    }) => {
      console.log('%cRedirect -->', 'color: orange', redirect);
      console.log('%cCurrency -->', 'color: green', currency);
      console.log('%cAmount -->', 'color: orange', amount);
      console.log('%cHashMemo -->', 'color: green', hashMemo);
      console.log('%cNftMint -->', 'color: orange', nftMint);
      console.log('%cEmailAddress -->', 'color: green', emailAddress);
      console.log('%cMember -->', 'color: orange', member);
      console.log('%cIsResultSubmit -->', 'color: green', isResultSubmit);
      console.log('%cEndpoint -->', 'color: green', endpoint);
      console.log(
        '%c_________________________________________________',
        'color: yellow',
      );

      /*
       * Check if the user is logged in
       */

      if (!providerPubKey) {
        return enqueueSnackbar('Please connect your wallet', {
          variant: 'warning',
        });
      }

      const paymentPayload = {
        redirect,
        currency,
        amount,
        hashMemo,
        nftMint,
        emailAddress,
        member,
        isResultSubmit,
        endpoint,
      };

      if (currency === FREE) {
        switch (redirect) {
          case routes.articles:
            return navigate(routes.articles);
          case routes.membership:
            return navigate(routes.membership);
          case routes.raffle:
            return navigate(routes.raffle);
          case routes.crossword:
            return navigate(routes.crossword);
          case mintUrl:
            return window.location.replace(mintUrl);
          default:
            navigate(redirect);
        }
      } else {
        switch (currency) {
          case SOL:
            return paySOL(paymentPayload);
          case SHDW:
          case DMND:
            return payDHMT(paymentPayload);
          default:
            return null;
        }
      }
    },
    [enqueueSnackbar, navigate, payDHMT, paySOL, providerPubKey],
  );

  return {
    paySOL,
    payDHMT,
    handlePay,
  };
};

export default usePay;
