import axios from 'axios';

const apiKey =
  process.env.REACT_APP_API_KEY || 'pk_ba981414548159f299cee0700d1df08c01';
const listID = process.env.REACT_APP_LIST_ID || 'VXL9Ky';

export const genBaseUrl =
  'https://shdw-drive.genesysgo.net/AzGbx4FqPEFJLs4m5sFrJVXQuhr29HpC35m4UGeVcRCP';

export const memberBaseUrl = 'https://a.klaviyo.com';

export const walletBaseUrl = 'https://claimingapi-1-q1861163.deta.app/';

const instanceMember = axios.create({
  baseURL: memberBaseUrl,
  params: { api_key: apiKey },
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
});

const instanceWallet = axios.create({
  baseURL: walletBaseUrl,
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
});

export const checkProfilesRequest = async email => {
  if (!apiKey && !listID && !email) return;

  const options = {
    data: {
      emails: [email],
      phone_numbers: [],
      push_tokens: [],
    },
  };

  try {
    const response = await instanceMember.post(
      `/api/v2/list/${listID}/get-members`,
      options,
    );

    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getNFTs = async (wallet, signal) => {
  try {
    const { data } = await instanceWallet.get(`/entangleddhowner/${wallet}`, {
      signal,
    });

    return data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const emailCollectionData = async (wallet, sha, payload) => {
  try {
    const { data } = await instanceWallet.post(`/info/${sha}`, payload);

    return data;
  } catch (error) {
    console.log('emailCollectionData', error);
  }
};

export const postTransactionData = async (claim_id, payload) => {
  try {
    const { data } = await instanceWallet.post(`/claims/${claim_id}`, payload);

    return data;
  } catch (error) {
    console.log('postTransactionData', error);
  }
};
