import { useEffect, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Connection } from '@solana/web3.js';
import { useSnackbar } from 'notistack';

import { PrivateRoute, LimitedRoute } from 'common/hoc';
import { AppLayout, ArticlesLayout } from 'common/layout';
import { Loader, Notification, Modal } from 'common/components';

import { setProviderPubKey, setConnection } from 'redux/provider/providerSlice';
import { useModal, useProvider, useLoader } from 'common/hooks';
import { NETWORK } from 'common/static/constants';

import {
  MainPage,
  MembershipPage,
  // MerchandisePage,
  EmailCollectionPage,
  CrosswordPage,
  RafflePage,
  NotFoundPage,
  ArticlesPage,
  articlesRoutes,
  routes,
} from './routes';

import 'common/utils/bufferFill';

const App = () => {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const { provider } = useProvider();
  const { isLoading } = useLoader();
  const { isModalOpen } = useModal();

  // Connection to the Solana cluster
  const connection = useMemo(() => new Connection(NETWORK, 'confirmed'), []);

  /*
   * React will call this useEffect everytime there is update in the provider variable.
   * Phantom provider provides 2 methods to listen on
   * 1. connect -> This method gets triggered when the wallet connection is successful
   * 2. disconnect -> This callback method gets triggered when the wallet gets disconnected from the application
   */

  //
  useEffect(() => {
    if (connection) dispatch(setConnection(connection));
  }, [connection, dispatch]);

  //
  useEffect(() => {
    if (provider && !provider.isConnected) provider.connect();
  }, [provider]);

  //
  useEffect(() => {
    if (provider) {
      provider.on('connect', async () => {
        enqueueSnackbar('Wallet got connected', {
          variant: 'success',
        });

        dispatch(setProviderPubKey(provider?.publicKey));
      });

      provider.on('disconnect', () => {
        enqueueSnackbar('Disconnected from wallet', {
          variant: 'info',
        });
      });
    }
  }, [connection, dispatch, enqueueSnackbar, provider]);

  if (isLoading) return <Loader isLoading />;

  return (
    <>
      <Routes>
        <Route path={routes.home} element={<AppLayout />}>
          <Route index element={<MainPage />} />
          <Route
            path={routes.raffle}
            element={<LimitedRoute component={<RafflePage />} />}
          />
          <Route
            path={routes.membership}
            element={<LimitedRoute component={<MembershipPage />} />}
          />
          <Route
            path={routes.crossword}
            element={<LimitedRoute component={<CrosswordPage />} />}
          />
          <Route
            path={routes.emailCollection}
            element={<LimitedRoute component={<EmailCollectionPage />} />}
          />
          {/* <Route
            path={routes.merchandise}
            element={<PrivateRoute component={<MerchandisePage />} />}
          /> */}
        </Route>

        <Route path={routes.articles} element={<ArticlesLayout />}>
          <Route
            index
            element={<LimitedRoute component={<ArticlesPage />} />}
          />
          {articlesRoutes.map(({ path, component: ArticlePage }) => (
            <Route
              key={path}
              path={path}
              element={<PrivateRoute component={<ArticlePage />} />}
            />
          ))}
        </Route>

        <Route path={routes.notFound} element={<NotFoundPage />} />
      </Routes>

      {isModalOpen && <Modal />}

      <Notification />
    </>
  );
};

export default App;
