import emailjs from '@emailjs/browser';
import { localStorageGet, localStorageSet } from 'common/utils/localStorage';
import { fillAnswers } from 'common/utils/fillAnswers';
import { initialResults } from 'common/static/results';
import { solscanUrl } from 'common/static/constants';

export const toDate = value => {
  if (!value) return;

  return new Date(value.toNumber() * 1000);
};

export const getTimerDisplayed = (pathname, routes) => {
  switch (pathname) {
    case routes.home:
    case `/${routes.raffle}`:
    case `/${routes.membership}`:
    case `/${routes.merchandise}`:
    case `/${routes.crossword}`:
    case `/${routes.articles}`:
      return false;
    default:
      return true;
  }
};

export const getTimeDuration = (minutes, seconds) => {
  const min = (59 - minutes).toString();
  const sec = (60 - seconds).toString();
  const formattedMinutes = min.length === 1 ? `0${min}` : min;
  const formattedSeconds = sec.length === 1 ? `0${sec}` : sec;
  const result = `${formattedMinutes}:${formattedSeconds}`;

  return result;
};

export const generateResults = () => {
  const data = localStorageGet('guesses');

  if (data && data.guesses) {
    fillAnswers('across', data.guesses);
    fillAnswers('down', data.guesses);
  }

  localStorageSet('results', initialResults);
};

export const sendEmail = ({
  sendToEmail = null,
  userAddress = null,
  role = null,
  signature = null,
}) => {
  if (!sendToEmail) return;

  const userTemplateID = process.env.REACT_APP_TEMPLATE_ID;
  const memberTemplateID = process.env.REACT_APP_TEMPLATE_PREMIUM_ID;

  const userTemplate = {
    to_email: sendToEmail,
    my_html: `
			<span>Hello DH!<span> 
			<span>Congratulations on successfully claiming your 3 months of Cryptonary Pro with DMNDS! Your receipt of payment can be found on chain at the link below.<span> 
			<span>Please note that your Cryptonary Pro account associated with this email will be updated to reflect this offer during the next bi-weekly DMNDS emissions. <span> 
			<span>We appreciate your patience in the meantime.<span> 
			<span>Check your transaction status at:</span>
			<span>You can check your transaction status:</span>
			<a href="${solscanUrl}/${signature}">
				here
			</a>
			`,
  };

  // const memberTemplate = {
  //   to_email: sendToEmail,
  //   my_html: `
  // 	<div>
  // 		<span>Email:</span>
  // 		<span>${userAddress}</span>
  // 	</div>
  // 	<div>
  // 		<span>You can check your transaction status:</span>
  // 		<a href="${solscanUrl}/${signature}">
  // 			here
  // 		</a>
  // 	</div>
  // 		`,
  // };

  emailjs
    .send(
      process.env.REACT_APP_SERVICE_ID,
      userTemplateID,
      userTemplate,
      // role === 'member' ? memberTemplateID : userTemplateID,
      // role === 'member' ? memberTemplate : userTemplate,
      process.env.REACT_APP_PUBKEY,
    )
    .then(response =>
      console.log('send email SUCCESS!', response.status, response.text),
    )
    .catch(err => console.log('send email FAILED...', err));
};

export const resultsToString = () => {
  const acrossAxisString =
    initialResults.across.reduce((acc, item) => {
      acc += item.answer ? `| ${item.answer} ` : `|  `;

      return acc;
    }, '') + '|';

  const downAxisString =
    initialResults.down.reduce((acc, item) => {
      acc += item.answer ? `| ${item.answer} ` : `|  `;

      return acc;
    }, '') + '|';

  const totalResultsString = (acrossAxisString + downAxisString).replace(
    '||',
    '|',
  );

  return totalResultsString;
};
