import React from 'react';
import { Box } from '@mui/material';

import { styles } from 'common/layout/AppWrapper/AppWrapper.styles';

const AppWrapper = ({ children }) => (
  <Box sx={styles.main} component="main">
    {children}
  </Box>
);

export default AppWrapper;
