import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { styles } from 'common/components/Answer/Answer.styles';

const AnswerBase = ({ number, question, answer }) => (
  <Box sx={styles.item} component="li">
    <Typography sx={styles.number} component="span">
      {number}.
    </Typography>
    <Typography sx={styles.answer} component="span">
      {answer ? answer : '-----'}
    </Typography>
    <Typography sx={styles.question} component="span">
      {question}
    </Typography>
  </Box>
);

const Answer = memo(AnswerBase);

export default Answer;
