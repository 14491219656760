import React from 'react';
import {
  Box,
  Toolbar,
  Typography,
  Button as MuiButton,
  Link,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { Button } from 'common/components';
import { useProvider } from 'common/hooks';
import staticContent from 'common/static/content.json';

import { toolbarStyles as styles } from 'common/layout/AppBasement/AppBasement.styles';

const { connected, notConnected } = staticContent.header;
const { know, reset, submit, technical } = staticContent.footer;

const BasementToolbar = ({ handleSubmit, handleReset }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const { providerPubKey } = useProvider();

  return (
    <Toolbar sx={styles.toolbar}>
      {!matches && (
        <Box sx={styles.provider}>
          <Typography sx={styles.key}>{providerPubKey?.toBase58()}</Typography>
          <Typography
            sx={providerPubKey ? styles.connected : styles.notConnected}
          >
            {providerPubKey ? connected : notConnected}
          </Typography>
        </Box>
      )}

      <Box sx={styles.technical}>
        <Typography variant="body1" color="text.secondary">
          {technical}
        </Typography>
        <Link sx={styles.link} href="#">
          {know}
        </Link>
      </Box>

      <Box sx={styles.buttons}>
        <MuiButton
          onClick={handleReset}
          sx={styles.reset}
          color="info"
          variant="contained"
        >
          {reset}
        </MuiButton>
        <Button
          title={submit}
          onClick={handleSubmit}
          disabled={!providerPubKey}
        />
      </Box>
    </Toolbar>
  );
};

export default BasementToolbar;
