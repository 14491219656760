import { useState, useEffect, useCallback } from 'react';
import { useProvider } from 'common/hooks';
import { getNFTs } from 'queries/instance';

const useNFTs = () => {
  const [NFTsList, setNFTsList] = useState([]);

  const { providerPubKey } = useProvider();

  const fetchNFTs = useCallback(
    async controller => {
      const nfts = await getNFTs(
        providerPubKey?.toBase58(),
        controller?.signal,
      );

      if (nfts) setNFTsList(nfts);
    },
    [providerPubKey],
  );

  useEffect(() => {
    const controller = new AbortController();

    if (providerPubKey) fetchNFTs(controller);

    return () => {
      controller?.abort();
    };
  }, [providerPubKey, fetchNFTs]);

  return {
    NFTsList,
  };
};

export default useNFTs;
