import React, { Suspense, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import { AppWrapper, AppBar, AppBasement } from 'common/layout';
import { Loader } from 'common/components';
import { styles } from 'common/layout/AppLayout/AppLayout.styles';
import { routes } from 'routes';

const AppLayout = () => {
  const [open, setOpen] = useState(false);
  const [showBasement, setShowBasement] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowBasement(location?.pathname === `/${routes.crossword}`);
  }, [location?.pathname]);

  const toggleDrawer = useCallback(() => setOpen(!open), [open]);

  return (
    <Box sx={styles.layout}>
      <AppBar toggleDrawer={toggleDrawer} />

      <AppWrapper>
        <Suspense fallback={<Loader isLoading />}>
          <Outlet />
        </Suspense>
      </AppWrapper>

      {showBasement && <AppBasement open={open} toggleDrawer={toggleDrawer} />}
    </Box>
  );
};

export default AppLayout;
