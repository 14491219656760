import drioOne from 'assets/image/slides/drip1.webp';
import drioTwo from 'assets/image/slides/drip2.webp';

export const items = [
  {
    img: drioOne,
  },
  {
    img: drioTwo,
  },
];
