import React from 'react';
import { Container } from '@mui/material';

import { styles } from 'common/layout/AppContainer/AppContainer.styles';

const AppContainer = ({ customStyles = {}, size = 'lg', children }) => (
  <Container sx={{ ...styles.container, ...customStyles }} maxWidth={size}>
    {children}
  </Container>
);

export default AppContainer;
