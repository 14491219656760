export const styles = {
  timer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  time: {
    color: 'custom.white',
  },
  timeEnd: {
    color: '#FF0000',
  },
};
