import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, useTheme, useMediaQuery } from '@mui/material';

import { BasementToolbar } from 'common/layout';
import { Swipeable } from 'common/components';
import { useSticky } from 'common/hooks';

import { modalOpened } from 'redux/modal/modalSlice';
import { gameResetActive } from 'redux/game/gameSlice';
import { initialResults } from 'common/static/results';
import { generateResults } from 'common/utils/misc';
import { styles } from 'common/layout/AppBasement/AppBasement.styles';

const AppBasementBase = ({ open, toggleDrawer }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const { sticky } = useSticky();

  const handleReset = useCallback(() => {
    dispatch(gameResetActive());

    initialResults.across.forEach(item => {
      item.answer = '';
    });
    initialResults.down.forEach(item => {
      item.answer = '';
    });
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    toggleDrawer();

    generateResults();

    dispatch(modalOpened('submit'));
  }, [dispatch, toggleDrawer]);

  const footerSx = sticky ? styles.footerSticky : styles.footer;

  return (
    <>
      {matches && (
        <Box sx={footerSx} component="footer">
          <BasementToolbar
            handleSubmit={handleSubmit}
            handleReset={handleReset}
          />
        </Box>
      )}

      {!matches && (
        <Swipeable open={open} toggleDrawer={toggleDrawer}>
          <BasementToolbar
            handleSubmit={handleSubmit}
            handleReset={handleReset}
          />
        </Swipeable>
      )}
    </>
  );
};

const AppBasement = memo(AppBasementBase);

export default AppBasement;
